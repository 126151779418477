import { HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { ChainName } from "@types";
import ChainLogo from "./ChainLogo";
import { ChevronDown } from "lucide-react";
import { useChainContext } from "../../context/ChainContext";
import { useHubContext } from "@context/OmnityHubContext";

export default function ChainButton({
  chainName,
  isSource,
  changable = true,
}: {
  chainName?: ChainName;
  isSource?: boolean;
  changable?: boolean;
}) {
  const { theme } = useHubContext();
  const isBitfinity = theme === "bitfinity";
  const { onShowChainsModal } = useChainContext();
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");
  const boxHoverWrapperBg = useColorModeValue("gray.200", "gray.600");

  return (
    <HStack
      w="100%"
      bg={isBitfinity ? "bg.darkMain" : boxWrapperBg}
      _hover={{
        bg: isBitfinity ? "bg.darkHover" : boxHoverWrapperBg,
      }}
      cursor={changable ? "pointer" : "auto"}
      py={2}
      px={2}
      borderRadius={4}
      justifyContent="space-between"
      onClick={() => changable && onShowChainsModal(!!isSource)}
    >
      <HStack>
        <ChainLogo chain={chainName} size={30} />
        <Text fontWeight={600} fontSize={20}>
          {chainName}
        </Text>
      </HStack>
      {changable && <ChevronDown />}
    </HStack>
  );
}
