import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { ChainID, OmnityWidgetProps, TabAction } from "@types";
import Footer from "@components/common/Footer";
import { ChainSelectProvider } from "./context/ChainContext";
import { TransferTokenckBTCProvider } from "./context/TransferTokenckBTCContext";
import { useHubContext } from "./context/OmnityHubContext";
import TransferckBTC from "./tabs/TransferckBTC";
import { TransferCkBTCProvider } from "./context/TransferckBTCContext";

const TABS = [TabAction.Transfer];

export default function CkBTC({
  width = 464,
  sourceChain,
  targetChain,
  tokenIds,
  reversible,
  isPeerToPeer,
  onTabChange,
  tab,
  customs,
}: OmnityWidgetProps) {
  const { onTabActionChange } = useHubContext();

  let tabs = TABS;
  if (customs === ChainID.sICP) {
    tabs = [];
  }

  return (
    <VStack
      id="omnity"
      w={{ base: "100%", md: width }}
      gap="2px"
      px={{ base: 2, md: 0 }}
      mt={{ base: 6, md: 0 }}
    >
      <Tabs
        variant="soft-rounded"
        w="100%"
        index={TABS.indexOf(tab ?? TabAction.Transfer)}
      >
        <TabList gap={{ base: 2, md: 4 }}>
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab}
                color="gray.100"
                onClick={() => {
                  onTabActionChange && onTabActionChange(tab);
                  onTabChange && onTabChange(tab);
                }}
                pos="relative"
              >
                {tab}
              </Tab>
            );
          })}
        </TabList>
        <TabPanels mt={2}>
          <TabPanel p={0}>
            <TransferCkBTCProvider
              sourceChain={sourceChain}
              targetChain={targetChain}
              tokenIds={tokenIds}
              reversible={reversible}
              isPeerToPeer={isPeerToPeer}
            >
              <ChainSelectProvider>
                <TransferTokenckBTCProvider>
                  <TransferckBTC width={width} />
                </TransferTokenckBTCProvider>
              </ChainSelectProvider>
            </TransferCkBTCProvider>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Footer />
    </VStack>
  );
}
