import {
  Addresses,
  AssetType,
  Chain,
  ChainID,
  ChainName,
  CustomsChainId,
  FeeTokenPrices,
  Network,
  TabAction,
} from "../types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import HubService from "@services/HubService";
import { useBTCWalletKit } from "@wallet-kits/btc-wallet-kit";
import { useICPWalletKit } from "@wallet-kits/icp-wallet-kit";
import { useEVMWalletKit } from "@wallet-kits/evm-wallet-kit";
import { useSOLWalletKit } from "@wallet-kits/sol-wallet-kit";
import { useOSMWalletKit } from "@wallet-kits/osm-wallet-kit";
import { useTonWalletKit } from "@wallet-kits/ton-wallet-kit/TONWalletProvider";

type WidgetTheme = "default" | "bitfinity";

/**
 * @property {chains} chains from hub.
 * @property {chainList} All chains supported, cause above chains not contains chain info.
 */
export interface HubContextProps {
  assetType: AssetType;
  tabAction: TabAction;
  chains: Chain[];
  chainList: ChainName[];
  customs: CustomsChainId;
  addresses: Addresses;
  hubService?: HubService;
  theme?: WidgetTheme;
  onTabActionChange?: (tabAction: TabAction) => void;
  feeTokenPrices?: FeeTokenPrices;
}

const initialState: HubContextProps = {
  assetType: AssetType.runes,
  tabAction: TabAction.Transfer,
  chains: [],
  customs: ChainID.Bitcoin,
  chainList: Object.values(ChainName),
  onTabActionChange: () => {},
  addresses: {},
};

const HubContext = createContext<HubContextProps>(initialState);

export function useHubContext() {
  return useContext(HubContext);
}

(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};

const chainsAtom = atomWithStorage<Chain[]>(`omnity.chains`, []);

export function HubProvider({
  assetType,
  customs,
  tab,
  network,
  theme = "default",
  onTabChange,
  children,
}: {
  assetType?: AssetType;
  customs: ChainID;
  tab: TabAction;
  network: Network;
  theme?: "default" | "bitfinity";
  onTabChange?: (action: TabAction) => void;
  children: React.ReactNode;
}) {
  const _assetType = assetType ?? AssetType.runes;
  const [chains, setChains] = useAtom(chainsAtom);
  const [tabAction, setTabAction] = useState(tab);
  const [feeTokenPrices, setFeeTokenPrices] = useState<FeeTokenPrices>();

  const { address: btcAddr } = useBTCWalletKit();
  const { address: icpAddr } = useICPWalletKit();
  const { address: evmAddr } = useEVMWalletKit();
  const { address: solAddr } = useSOLWalletKit();
  const { address: osmAddr } = useOSMWalletKit();
  const { address: tonAddr } = useTonWalletKit();

  const hubService = useMemo(() => {
    return new HubService(network);
  }, [network]);

  useEffect(() => {
    fetch(`https://api.omnity.network/api/price/bridge`)
      .then((res) => res.json())
      .then((res) => {
        setFeeTokenPrices(res.prices);
      });
  }, []);

  useEffect(() => {
    function fetchChainsNTokens() {
      hubService.fetchChains(_assetType).then((res) => {
        setChains(res);
      });
    }
    fetchChainsNTokens();
    const tick = setInterval(fetchChainsNTokens, 1000 * 60 * 5);
    return () => clearInterval(tick);
  }, [_assetType]);

  useEffect(() => {
    setTabAction(tab);
  }, [tab]);

  const contextValue = useMemo(() => {
    let _chains: Chain[] = chains;
    if (_assetType === AssetType.ckbtc) {
    } else {
      const customsChain = chains.find((c) => c.chain_id === customs);
      const counterparties = chains.filter((c) =>
        customsChain?.counterparties.includes(c.chain_id),
      );
      _chains = customsChain ? [customsChain, ...counterparties] : [];
    }
    const value: HubContextProps = {
      assetType: _assetType,
      tabAction,
      chains: _chains,
      theme,
      chainList: _chains.map((c) => c.chain_name),
      customs: customs as CustomsChainId,
      hubService,
      onTabActionChange: (tabAction: TabAction) => {
        setTabAction(tabAction);
        onTabChange?.(tabAction);
      },
      addresses: {
        btcAddr,
        icpAddr,
        evmAddr,
        solAddr,
        osmAddr,
        tonAddr,
      },
      feeTokenPrices,
    };
    return value;
  }, [
    chains,
    network,
    hubService.canisterId,
    tabAction,
    customs,
    evmAddr,
    btcAddr,
    icpAddr,
    solAddr,
    osmAddr,
    tonAddr,
    feeTokenPrices,
    _assetType,
    theme,
  ]);

  return (
    <HubContext.Provider value={contextValue}>{children}</HubContext.Provider>
  );
}
