import {
  Token,
  BridgeStep,
  OnBridgeParams,
  OnBurnParams,
  Ticket,
  TicketStatusResult,
  ChainID,
  BridgeFee,
  Chain,
  AssetType,
  GenerateTicketResult,
  TxStatus,
} from "@types";
import { ActorSubclass } from "@dfinity/agent";
import {
  idlFactory as InterfaceFactory,
  _SERVICE,
} from "./candids/CosmRoute.did";
import { createActor } from "./candids/index";
import BaseService from "./BaseService";
import { BTC_ICON, DEFAULT_TOKEN } from "src/utils/constants";
import { StargateClient } from "@cosmjs/stargate";
import posthog from "posthog-js";

const OSMOSIS_RPC = "https://rpc.osmosis.zone";

export default class OsmosisRouteService extends BaseService {
  actor: ActorSubclass<_SERVICE>;
  static BTC_CONFIRMATIONS_LIMIT = 4;
  static BTC_DENOM =
    "factory/osmo1z6r6qdknhgsc0zeracktgpcxf43j6sekq07nw8sxduc9lg0qjjlqfu25e3/alloyed/allBTC";

  constructor(chain: Chain) {
    super(chain);
    this.actor = createActor<_SERVICE>(chain.canister_id, InterfaceFactory);
  }

  async getTokenList(): Promise<Token[]> {
    if (this.chain.asset_type === AssetType.ckbtc) {
      const token: Token = {
        id: OsmosisRouteService.BTC_DENOM,
        name: "BTC",
        symbol: "BTC",
        decimals: 8,
        icon: BTC_ICON,
        balance: 0n,
        token_id: DEFAULT_TOKEN[ChainID.BitcoinckBTC],
        fee: 0n,
        chain_id: ChainID.Osmosis,
      };

      return Promise.resolve([token]);
    } else {
      const tokens = await this.actor.get_token_list();

      return tokens.map((t) => {
        return {
          id: t.token_denom[0] ?? "",
          name: t.symbol,
          symbol: t.symbol,
          decimals: t.decimals,
          icon: t.icon[0],
          balance: 0n,
          token_id: t.token_id,
          fee: 0n,
          chain_id: ChainID.Osmosis,
        };
      });
    }
  }

  async fetchTokens(token_ids?: string[], address?: string): Promise<Token[]> {
    try {
      let tokenList = this.chain.token_list || [];

      if (Array.isArray(token_ids) && token_ids.length > 0) {
        tokenList = token_ids
          .map((id) => tokenList.find((r) => r.token_id === id))
          .filter((t) => !!t) as any;
      }
      if (!address) {
        return tokenList;
      }

      const client = await StargateClient.connect(OSMOSIS_RPC);
      const tokens = await Promise.all(
        tokenList.map(async (t) => {
          let balance = 0n;
          try {
            const _balance = await client.getBalance(address, t.id);
            balance = BigInt(_balance.amount);
          } catch (error) {}
          return { ...t, balance };
        }),
      );

      return tokens;
    } catch (error) {
      return [];
    }
  }

  getBridgeSteps(token?: Token): BridgeStep[] {
    return [
      {
        title: "Transfer",
        description: "Send your Assets",
      },
      {
        title: "Bridge",
        description: "Generate Ticket",
      },
    ];
  }

  async onBridge(params: OnBridgeParams): Promise<string> {
    const {
      sourceAddr,
      targetAddr,
      amount,
      transfer,
      setStep,
      targetChainId,
      token,
    } = params;
    if (!transfer) {
      throw new Error("Transfer function is required");
    }
    const tx_hash = await transfer({
      fromAddr: sourceAddr,
      toAddr: targetAddr,
      amount,
      assetType: this.chain.asset_type,
      targetChainId,
      tokenId: token.token_id,
    });

    setStep && setStep(1);

    return tx_hash;
  }
  static async getTxStatus(ticket: Ticket): Promise<TxStatus> {
    return Promise.resolve("success");
  }

  onBurn(params: OnBurnParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  onMint(params: OnBridgeParams): Promise<string> {
    throw new Error("Method not implemented.");
  }
  async generateTicket(ticket: Ticket): Promise<GenerateTicketResult> {
    if (!ticket.ticket_id) {
      throw new Error("Ticket ID is required");
    }
    const result = await this.actor.generate_ticket(ticket.ticket_id);
    if ("Err" in result) {
      const error = Object.keys(result.Err).join("");
      posthog.capture("ticket generate error", {
        ...ticket,
        error,
      });
      if (error === "TxAlreadyHandled") {
        return { ticket: { ...ticket, finalized: true } };
      }
      return { ticket: { ...ticket, finalized: false }, message: error };
    }
    posthog.capture("ticket generate ok", {
      ...ticket,
    });
    return { ticket: { ...ticket, finalized: true } };
  }
  getTicketStatus(ticket_id: string): Promise<TicketStatusResult> {
    throw new Error("Method not implemented.");
  }
  async getBridgeFee(
    targetChainId: ChainID,
    token?: Token,
  ): Promise<BridgeFee> {
    const _targetChainId =
      this.chain.asset_type === AssetType.ckbtc ? ChainID.sICP : targetChainId;
    const [fee] = await this.actor.get_fee(_targetChainId);

    return Promise.resolve({
      fee: fee ?? 0n,
      symbol: "OSMO",
      decimals: 6,
    });
  }
}
