import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import PubSub from "pubsub-js";
import { EventType, WalletType } from "../types";
import OSMWalletKitModal from "./OSMWalletKitModal/index";
import { Window as KeplrWindow } from "@keplr-wallet/types";
import { StargateClient, GasPrice } from "@cosmjs/stargate";
import { CHAIN_INFO } from "../constants";
import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { Decimal } from "@cosmjs/math";
import OsmosisRouteService from "@services/OsmosisRouteService";
import { AssetType, ChainID } from "@types";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface Window extends KeplrWindow {}
}

interface TransferParams {
  amount: bigint;
  fromAddr: string;
  toAddr: string;
  assetType: AssetType;
  targetChainId: ChainID;
  tokenId: string;
}

interface OsmosisWalletKitProviderProps {
  address: string;
  connector?: WalletType;
  balance?: bigint;
  onShowModal: () => void;
  onHideModal: () => void;
  onDisconnect: () => void;
  transfer: (params: TransferParams) => Promise<string>;
}

const OsmosisWalletKitProviderContext =
  createContext<OsmosisWalletKitProviderProps | null>(null);

const accountAtom = atomWithStorage<{
  address: string;
  connector?: WalletType;
  balance?: string;
}>("owk.account", {
  address: "",
  connector: undefined,
  balance: "0",
});

export const OSMOSIS_CONTRACT_ADDRESS =
  "osmo10c4y9csfs8q7mtvfg4p9gd8d0acx0hpc2mte9xqzthd7rd3348tsfhaesm";

export function OSMWalletProvider({ children }: { children: ReactNode }) {
  const [account, setAccount] = useAtom(accountAtom);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (account.address) {
      async function getBalance() {
        try {
          const rpcEndpoint = CHAIN_INFO.rpc;
          const client = await StargateClient.connect(rpcEndpoint);
          const denom = OsmosisRouteService.BTC_DENOM;
          const balance = await client.getBalance(account.address, denom);
          setAccount((prev) => ({ ...prev, balance: balance?.amount ?? "0" }));
        } catch (error) {
          console.error("Failed to get balance", error);
        }
      }

      getBalance();

      const tick = setInterval(getBalance, 30000);
      return () => clearInterval(tick);
    } else {
      setAccount((prev) => ({ ...prev, balance: "0" }));
    }
  }, [account.address, setAccount]);

  useEffect(() => {
    const connectListener = PubSub?.subscribe(
      EventType.ON_CONNECT,
      (
        _: string,
        { address, connector }: { address: string; connector: WalletType },
      ) => {
        setAccount({ address, connector });
      },
    );
    return () => {
      PubSub?.unsubscribe(connectListener);
    };
  }, [setAccount]);

  const transfer = async (params: TransferParams) => {
    const keplr = window.keplr;
    if (!keplr) {
      throw new Error("Keplr extension not installed");
    }
    const { amount, fromAddr, toAddr, assetType, targetChainId, tokenId } =
      params;
    await keplr.experimentalSuggestChain(CHAIN_INFO);
    await keplr.enable(CHAIN_INFO.chainId);
    let offlineSigner: any = keplr.getOfflineSigner(CHAIN_INFO.chainId);
    const accounts = await offlineSigner.getAccounts();
    if (accounts.length === 0) {
      throw new Error("There is no available key");
    }
    const key = await keplr.getKey(CHAIN_INFO.chainId);
    const isLedger = key.isNanoLedger;
    if (isLedger) {
      offlineSigner = keplr.getOfflineSignerOnlyAmino(CHAIN_INFO.chainId);
    }
    const client = await SigningCosmWasmClient.connectWithSigner(
      CHAIN_INFO.rpc,
      offlineSigner,
      {
        gasPrice: new GasPrice(Decimal.one(1), "uosmo"),
      },
    );

    let txHash = "";

    const feeInfo = await client.queryContractSmart(OSMOSIS_CONTRACT_ADDRESS, {
      get_target_chain_fee: {
        target_chain: targetChainId,
      },
    });
    if (assetType === AssetType.ckbtc) {
      const msg = {
        redeem_all_b_t_c: {
          token_id: tokenId,
          receiver: toAddr,
          amount: amount.toString(),
          target_chain: targetChainId,
        },
      };

      const result = await client.execute(
        fromAddr,
        OSMOSIS_CONTRACT_ADDRESS,
        msg,
        "auto",
        "",
        [
          {
            denom: OsmosisRouteService.BTC_DENOM,
            amount: amount.toString(),
          },
          {
            denom: "uosmo",
            amount: `${feeInfo.fee_amount}`,
          },
        ],
      );
      txHash = result.transactionHash;
    } else {
      const msg = {
        generate_ticket: {
          token_id: tokenId,
          sender: fromAddr,
          receiver: toAddr,
          amount: amount.toString(),
          target_chain: targetChainId,
          action: "redeem",
        },
      };

      const result = await client.execute(
        fromAddr,
        OSMOSIS_CONTRACT_ADDRESS,
        msg,
        "auto",
        "",
        [
          {
            denom: "uosmo",
            amount: `${feeInfo.fee_amount}`,
          },
        ],
      );
      txHash = result.transactionHash;
    }
    return txHash;
  };

  const contextValue = useMemo(
    () => ({
      address: account.address,
      connector: account.connector,
      balance: BigInt(account.balance ?? 0),
      onShowModal: () => {
        setShowModal(true);
      },
      onHideModal: () => {
        setShowModal(false);
      },
      onDisconnect: () => {
        setAccount({ address: "", connector: undefined });
      },
      transfer,
    }),
    [account.address, account.connector, account.balance, setAccount],
  );

  return (
    <OsmosisWalletKitProviderContext.Provider value={contextValue}>
      {children}
      <OSMWalletKitModal open={showModal} onClose={contextValue.onHideModal} />
    </OsmosisWalletKitProviderContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useOSMWalletKit() {
  const context = useContext(OsmosisWalletKitProviderContext);

  if (!context) {
    throw new Error(
      "useOSMWalletKit must be used within a OSMWalletKitProvider",
    );
  }

  return context;
}
