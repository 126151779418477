import {
  HStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
  chakra,
  VStack,
  useColorModeValue,
  Avatar,
  Box,
  useToast,
} from "@chakra-ui/react";
import { useHubContext } from "../context/OmnityHubContext";
import { ChevronDown, PackageOpen, Search } from "lucide-react";
import { useEffect, useState } from "react";
import { ChainID, Rune, Token } from "@types";
import { request, gql } from "graphql-request";
import { runesIndexerApi } from "@services/BitcoinCustomsService";
import { useAddRunesContext } from "../context/AddRunesContext";
import ItemSelected from "./common/ItemSelected";
import CloseButtonForModal from "./common/CloseButtonForModal";
import { TOAST_ERROR_DURATION } from "src/utils/constants";

const SearchIcon = chakra(Search);
const PackageOpenIcon = chakra(PackageOpen);

export default function RunesSelector() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [keyword, setKeyword] = useState("");
  const { chains, theme } = useHubContext();
  const [runes, setRunes] = useState<Rune[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isBitfinity = theme === "bitfinity";
  const textColor = useColorModeValue("gray.800", "gray.100");
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");
  const boxHoverWrapperBg = useColorModeValue("gray.200", "gray.600");

  let tokens: Token[] = [];
  const bitcoinChain = chains.find(
    (chain) => chain.chain_id === ChainID.Bitcoin,
  );
  if (bitcoinChain) {
    tokens = bitcoinChain.token_list ?? [];
  }

  const { selectedRune, onSelectRune } = useAddRunesContext();

  useEffect(() => {
    setIsLoading(true);
    getTopTransferRunes().then((runes) => {
      setIsLoading(false);
      setRunes(runes);
    });
  }, []);

  useEffect(() => {
    if (keyword.trim() === "") {
      return;
    }
    searchRunes(keyword.trim().toUpperCase()).then((runes) => {
      setRunes(runes);
    });
  }, [keyword.trim()]);

  const toast = useToast();

  return (
    <>
      <HStack
        w="100%"
        bg={isBitfinity ? "bg.darkMain" : boxWrapperBg}
        _hover={{
          bg: isBitfinity ? "bg.darkHover" : boxHoverWrapperBg,
        }}
        cursor="pointer"
        py={2}
        px={2}
        borderRadius={4}
        justifyContent="space-between"
        onClick={onOpen}
      >
        <HStack>
          {selectedRune && (
            <Avatar
              name={selectedRune.spaced_rune}
              src={selectedRune.icon}
              boxSize={8}
            />
          )}
          <Text
            fontSize={18}
            fontWeight={selectedRune?.spaced_rune ? 600 : 400}
            lineHeight={selectedRune ? 1 : 2}
          >
            {selectedRune?.spaced_rune ?? "Select Runes"}
          </Text>
        </HStack>
        <ChevronDown />
      </HStack>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <ModalHeader>Select Runes</ModalHeader>
          <CloseButtonForModal />
          <ModalBody p={0}>
            <HStack px={6}>
              <InputGroup size="lg">
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  placeholder="Search Runes By Name"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value.toUpperCase())}
                />
              </InputGroup>
            </HStack>

            <VStack
              maxH={300}
              overflowY="scroll"
              overflowX="hidden"
              overflowWrap="normal"
              willChange="transform"
              mt={2}
              gap={0}
            >
              {runes.map((item, idx) => {
                const isLast = idx === runes.length - 1;
                const tokenAdded = tokens.find((t) => t.id === item.rune_id);
                const isSelected = selectedRune?.rune_id === item.rune_id;
                return (
                  <RunesItem
                    key={item.id}
                    item={{ ...item, icon: tokenAdded?.icon ?? item.icon }}
                    isLast={isLast}
                    selected={isSelected}
                    added={!!tokenAdded}
                    onSelect={(rune) => {
                      if (rune.divisibility > 18) {
                        toast({
                          description:
                            "Rune divisibility should be less than 18",
                          status: "error",
                          duration: TOAST_ERROR_DURATION,
                        });
                        return;
                      }
                      onSelectRune?.(rune);
                      setTimeout(onClose, 120);
                    }}
                  />
                );
              })}
              {!isLoading && runes.length === 0 && (
                <VStack py={2} gap={0} color="gray.500">
                  <PackageOpenIcon size={36} />
                  <Text>{"There's no runes"}</Text>
                </VStack>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

function RunesItem({
  item,
  selected,
  added = false,
  isLast = false,
  onSelect,
}: {
  item: Rune;
  selected?: boolean;
  added?: boolean;
  isLast?: boolean;
  onSelect?: (rune: Rune) => void;
}) {
  const boxWrapperHoverBg = useColorModeValue("gray.100", "gray.800");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <HStack
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      py={2}
      px={6}
      cursor="pointer"
      borderBottomLeftRadius={isLast ? 12 : 0}
      borderBottomRightRadius={isLast ? 12 : 0}
      borderBottomWidth={isLast ? 0 : 0.5}
      borderBottomColor={borderColor}
      _hover={{ bg: boxWrapperHoverBg }}
      onClick={() => onSelect && onSelect(item)}
    >
      <HStack>
        <Avatar name={item.spaced_rune} src={item.icon} boxSize={10} />
        <VStack gap={1} alignItems="flex-start">
          <Text
            fontWeight={600}
            fontSize={16}
            lineHeight={1}
            isTruncated
            maxW={260}
          >
            {item.spaced_rune}
          </Text>
          <HStack>
            {added && (
              <Box bg="teal.300" color="white" px={2} borderRadius="full">
                <Text color="black" fontWeight={600} fontSize={12}>
                  Added
                </Text>
              </Box>
            )}
            <Text color="#999" fontSize={12} maxW={160} isTruncated>
              #{item.number}
            </Text>
          </HStack>
        </VStack>
      </HStack>
      <Box>{selected && <ItemSelected size={24} />}</Box>
    </HStack>
  );
}

async function searchRunes(keyword: string) {
  try {
    const document = gql`
      {
        rs_rune_stats(order_by: {weight: desc, weekly_transfer_count: desc_nulls_last}, limit: 10, where: {runes: {spaced_rune: {_iregex: "${keyword}"}}}) {
          runes {
            rune_id
            spaced_rune
            symbol
            id
            number
            etching
            divisibility
          }
        }
      }
    `;
    const result = await request(`${runesIndexerApi}/v1/graphql`, document);
    return (result as any).rs_rune_stats.map((item: any) => {
      return {
        ...item.runes,
        icon: `https://ordinals.com/content/${item.runes.etching}i0`,
      };
    }) as Rune[];
  } catch (error) {
    return [];
  }
}

async function getTopTransferRunes() {
  try {
    const document = gql`
      {
        rs_rune_stats(
          order_by: { weight: desc, weekly_transfer_count: desc_nulls_last }
          limit: 10
        ) {
          runes {
            rune_id
            spaced_rune
            symbol
            id
            number
            etching
            divisibility
          }
        }
      }
    `;
    const result = await request(`${runesIndexerApi}/v1/graphql`, document);
    return (result as any).rs_rune_stats.map((item: any) => {
      return {
        ...item.runes,
        icon: `https://ordinals.com/content/${item.runes.etching}i0`,
      };
    }) as Rune[];
  } catch (error) {
    return [];
  }
}
