import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Button,
  ModalBody,
  useColorModeValue,
  useToast,
  VStack,
  Text,
  Image,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  ChainID,
  ChainName,
  OnBridgeParams,
  Ticket,
  TicketInteractStep,
  TicketAction,
  BridgeFee,
  GenerateTicketResult,
} from "../../types";
import { formatFee, formatUnits, readableNumber } from "../../utils/format";
import { useMintContext } from "../../context/MintContext";
import TicketTxStatus from "@components/TicketTxStatus";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import useTickets from "@hooks/useTickets";
import useMintService from "@hooks/useMintService";
import ChainLogo from "@components/common/ChainLogo";
import { useICPWalletKit } from "@wallet-kits/icp-wallet-kit";
import { useSOLWalletKit } from "@wallet-kits/sol-wallet-kit";
import TxSubmitButton from "./TxSubmitButton";
import { useHubContext } from "../../context/OmnityHubContext";
import { TOAST_ERROR_DURATION } from "src/utils/constants";

export default function ConfirmMint() {
  const { mintChain, mintAddr, token, receiverAddr, amount } = useMintContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentTicket, setCurrentTicket] = useState<Ticket>();
  const [interactStep, setInteractStep] = useState(TicketInteractStep.START);
  const [needRetry, setNeedRetry] = useState(false);

  const { executeService } = useMintService();
  const { feeTokenPrices } = useHubContext();

  const toast = useToast();
  const _onClose = () => {
    onClose();
    setNeedRetry(false);
    setInteractStep(TicketInteractStep.START);
  };
  const textColor = useColorModeValue("gray.800", "gray.100");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const { addTicket } = useTickets();
  const [bridgeFee, setBridgeFee] = useState<BridgeFee>();

  const { transfer: transferICP, createActor } = useICPWalletKit();
  const { transfer: transferSOL } = useSOLWalletKit();

  useEffect(() => {
    if (executeService) {
      executeService
        .getBridgeFee(ChainID.Bitcoin, token)
        .then(setBridgeFee)
        .catch(console.error);
    }
  }, [executeService?.chain.chain_id]);

  const onConfirm = async () => {
    try {
      setNeedRetry(false);
      if (!mintChain) {
        throw new Error("Please select chain");
      }
      if (!executeService) {
        throw new Error("Failed to get burn service");
      }
      if (!mintAddr) {
        throw new Error("Please connect your wallet first");
      }
      if (!token) {
        throw new Error("Please select a token first");
      }
      if (!receiverAddr) {
        throw new Error("Please input receiver address");
      }

      const dst_chain = ChainID.Bitcoin;
      const params: OnBridgeParams = {
        sourceAddr: mintAddr,
        targetAddr: receiverAddr,
        token,
        amount: 0n,
        targetChainId: dst_chain,
      };
      if (mintChain === ChainName.ICP) {
        params.createActor = createActor;
        params.transfer = transferICP;
      } else if (mintChain === ChainName.Solana) {
        params.transfer = transferSOL;
      }
      setInteractStep(TicketInteractStep.SEND_TX);
      const ticket_id = await executeService.onMint(params);

      let finalized = false;
      if (mintChain === ChainName.ICP) {
        finalized = true;
        setInteractStep(TicketInteractStep.COMPLETED);
      } else {
        setInteractStep(TicketInteractStep.WAIT_TX);
      }

      const _ticket: Ticket = {
        type: TicketAction.Mint,
        token: token.token_id,
        dst_chain,
        src_chain: executeService.chain.chain_id,
        ticket_id,
        decimals: token.decimals,
        symbol: token.symbol,
        amount: amount.toString() ?? "0",
        receiver: receiverAddr,
        sender: mintAddr,
        ticket_time: Date.now(),
        finalized,
      };
      setCurrentTicket(_ticket);

      toast({
        description: "Transaction has been sent, stay tuned",
        status: "success",
        duration: 3000,
      });
      addTicket(_ticket);
    } catch (error) {
      setNeedRetry(true);
      toast({
        description: (error as Error).message,
        status: "error",
        duration: TOAST_ERROR_DURATION,
      });
    }
  };

  const rows = [
    {
      title: "Receiver",
      value: (
        <Text color="#008cd5">
          {receiverAddr.slice(0, 8) + "..." + receiverAddr.slice(-6)}
        </Text>
      ),
    },
    {
      title: "Chain",
      value: (
        <HStack>
          <ChainLogo chain={mintChain} size={20} />
          <Text>{mintChain}</Text>
        </HStack>
      ),
    },
    {
      title: "Mint Fee",
      value: (
        <Text
          fontWeight={600}
          inlineSize={300}
          textAlign={{ base: "left", md: "right" }}
        >
          {formatFee(bridgeFee, feeTokenPrices)}
        </Text>
      ),
    },
  ];

  return (
    <>
      <Button
        colorScheme="blue"
        cursor="pointer"
        w="98%"
        fontSize={22}
        py={8}
        borderRadius={8}
        onClick={onOpen}
      >
        Confirm
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={_onClose}
      >
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <CloseButtonForModal />
          <ModalHeader>Mint</ModalHeader>
          <ModalBody pb={6}>
            <VStack gap={{ base: 2, md: 1 }}>
              <Image src={token?.icon} w={120} h={120} borderRadius="full" />
              <Text fontSize={32} fontWeight="bold">
                +{readableNumber(formatUnits(amount, token?.decimals))}{" "}
                {token?.symbol}
              </Text>
              <VStack w="100%" gap={{ base: 2, md: 1 }} mb={4}>
                {rows.map((row) => {
                  return (
                    <HStack
                      key={row.title}
                      w="100%"
                      justifyContent="space-between"
                      py={2}
                      borderBottomWidth={0.5}
                      borderBottomColor={borderColor}
                    >
                      <Text>{row.title}</Text>
                      <Text>{row.value}</Text>
                    </HStack>
                  );
                })}
              </VStack>
              {currentTicket && (
                <TicketTxStatus
                  service={executeService}
                  ticket={currentTicket}
                  onReverted={() => {
                    setNeedRetry(true);
                  }}
                  onGenerateTicket={() =>
                    setInteractStep(TicketInteractStep.GEN_TICKET)
                  }
                  onCompleted={(result: GenerateTicketResult) =>
                    setInteractStep(TicketInteractStep.COMPLETED)
                  }
                />
              )}

              <TxSubmitButton
                step={interactStep}
                onConfirm={onConfirm}
                onClose={_onClose}
                ticketType={TicketAction.Mint}
                needRetry={needRetry}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
