import { HStack, Image, Text } from "@chakra-ui/react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

type EvmAddressProps = {
  isSource?: boolean;
};
export default function EvmAddress({ isSource }: EvmAddressProps) {
  const { connector } = useAccount();
  return (
    <HStack>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === "authenticated");

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return null;
                }

                let _address = null;
                if (chain.unsupported && isSource) {
                  _address = (
                    <Text onClick={openChainModal} color="red">
                      Wrong network
                    </Text>
                  );
                } else {
                  _address = (
                    <Text
                      color="#008cd5"
                      cursor="pointer"
                      onClick={openAccountModal}
                      fontFamily="monospace"
                    >
                      {account.displayName}
                    </Text>
                  );
                }

                return _address;
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>{" "}
      {connector && connector.icon && (
        <Image
          src={connector.icon}
          alt={connector.name}
          boxSize={6}
          borderRadius={4}
        />
      )}
    </HStack>
  );
}
